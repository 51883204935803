import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import USGBC from "../../static/files/USGBC.pdf"
import CO_Jobs_Final_Report from "../../static/files/CO_Jobs_Final_Report_December2008.pdf"

import BrittanyBennett from "../images/BrittanyBennett.png"
import KyleGracey from "../images/KyleGracey.jpg"

const AboutPage = ({data, location}) => {
  const checkURL = () => {
    const greenJobsDefinition = location.state ? location.state.greenJobFAQ : false
    console.log(greenJobsDefinition)
    return greenJobsDefinition
  }
  const [studiesShown, setStudiesShown] = useState(false)
  const [greenJobFAQ, setGreenJobFAQ] = useState(checkURL())
  const [occupationFAQ, setOccupationFAQ] = useState(false)
  const [allGreenFAQ, setAllGreenFAQ] = useState(false)
  const [projectionFAQ, setProjectionFAQ] = useState(false)
  const [limitationsFAQ, setLimitationsFAQ] = useState(false)

  return (
    <Layout>
      <SEO title="About" />
      <div className="container">
        <div className="border-bottom">
          <h1 className="py-2" id="overview">Overview</h1>
          <p>Experts agree that ambitious U.S. climate policy will create millions of jobs. But, often lost in those numbers are the specific occupations real people will hold. This tool tries to make information about common green jobs easier to access, and show how green jobs policies will benefit many people. With it, you can:
          </p>
          <ul>
            <li>Explore common green jobs</li>
            <li>Compare them to other jobs</li>
            <li>Learn more about the characteristics of common green jobs and the people who hold them today</li>
          </ul>
        </div>

        <div className="border-bottom">
          <h3 className="pt-4 pb-2" id="faq">FAQ</h3>
          <h6 id="green-job-faq"><a href="javascript:;" onClick={() => setGreenJobFAQ(!greenJobFAQ)}>What is a “common green job”?</a></h6>
          {greenJobFAQ &&
          <p>A common green job is an occupation that researchers have cited as being likely to see growth due to green policies. You can see the full list of studies used in this research in the <span><Link to="#methodology">Methodology section</Link></span>.
            <br />
            <br />These are probably not the only occupations that will benefit from ambitious climate policy. These are the ones that are most commonly mentioned, and might benefit more than other occupations. Likewise, not every occupation listed here may benefit from every possible policy.
          </p>
          }
          <h6 id="occupation-faq"><a href="javascript:;" onClick={() => setOccupationFAQ(!occupationFAQ)}>What is an occupation versus a job?</a></h6>
          {occupationFAQ && 
          <p>This research uses “occupation” to mean a profession characterized by a unique set of skills and activities. Examples include <a href="/job/electricians">electricians</a> or <a href="/job/preschool-and-kindergarten-teachers">preschool and kindergarten teachers</a>. “Job” is used both as the equivalent of occupation, and also to refer to the number of people holding a particular occupation.
          </p>
          }
          <h6 id="all-green-faq"><a href="javascript:;" onClick={() => setAllGreenFAQ(!allGreenFAQ)}>Is every job in an occupation a green job?</a></h6>
          {allGreenFAQ && 
          <p>It depends on the occupation. While every <a href="/job/wind-turbine-service-technicians">wind turbine service technician</a> could be considered a green job, not every <a href="/job/carpenters">carpenter</a> will work in a green field. More ambitious climate policies will tend to increase the number of green jobs in an occupation.</p>
          }
          <h6 id="projection-faq"><a href="javascript:;" onClick={() => setProjectionFAQ(!projectionFAQ)}>Is this projecting number of jobs created?</a></h6>
          {projectionFAQ && 
          <p>This is not a jobs projection. This is what these occupations look like today. This research answers the question of what specific occupations will likely benefit most from ambitious climate policy. For more details of where the data comes from, see the <span><Link to="#methodology">Methodology section</Link></span>.
          </p>
          }
          <h6 id="limitations-faq"><a href="javascript:;" onClick={() => setLimitationsFAQ(!limitationsFAQ)}>For some occupations, the number of jobs in the states and territories doesn't add up to the total number of jobs in the country.</a></h6>
          {limitationsFAQ &&
          <p>This is a limitation of the underlying government data used for this tool.</p>
          }
        </div>

        <div className="border-bottom">
          <h3 className="pt-4 pb-2" id="methodology">Methodology</h3>
          <h5>Identifying Green Occupations</h5>
          <p>Due to the significant number of green jobs studies already produced, rather than attempting to define green jobs ourselves, we sought to identify occupations listed in previous studies. We chose this approach to avoid reliance on any one study’s methodology for identifying occupations, and to highlight how diverse green occupations can be. We used the following criteria to select studies:
          </p>
          <ul>
            <li className="pl-3">Produced since about 2008, since this is when the bulk of green jobs research has occurred, and to ensure the research was relevant to the current economy</li>
            <li className="pl-3">Referenced concepts like the Green New Deal, green jobs, or other relevant descriptors, such as renewable energy jobs or energy efficiency jobs</li>
            <li className="pl-3">Analyzed investments or policies in large portions of the economy</li>
            <li className="pl-3">Examined the entire United States</li>
            <li className="pl-3">Named specific occupations</li>
          </ul>

          <p><i>Think we missed a common green job or study? <span><Link to="#researchers">Contact the researchers</Link></span>.</i></p>

          <p>Based on these criteria, we identified the following studies, listed from oldest to newest. <a href="javascript:;" onClick={() => setStudiesShown(!studiesShown)}>Click to {studiesShown ? 'hide' : 'show'}.</a></p>
          { studiesShown && 
          <ul id="studies">
            <li className="pl-3">Pollin, Robert, and Jeannette Wicks-Lim. 2008. “JOB OPPORTUNITIES FOR THE GREEN ECONOMY: A STATE-BY-STATE PICTURE OF OCCUPATIONS THAT GAIN FROM GREEN INVESTMENTS.” Political Economy Research Institute. <a href="https://www.peri.umass.edu/fileadmin/pdf/other_publication_types/Green_Jobs_PERI.pdf" target="_blank" rel="noreferrer">Available here</a>.</li>
            <li className="pl-3">White, Sarah, and Jason Walsh. 2008. “Greener Pathways: Jobs and Workforce Development in the Clean Energy Economy.” Center on Wisconsin Strategy. <a href="https://cows.org/wp-content/uploads/sites/1368/2020/05/2008-Greener-Pathways-Jobs-and-Workforce-Development-in-the-Clean-Energy-Economy.pdf" target="_blank" rel="noreferrer">Available here</a>.</li>
            <li className="pl-3">Booz Allen Hamilton. 2008. “U.S. GREEN BUILDING COUNCIL GREEN JOBS STUDY.” USGBC. <a href={USGBC} target="_blank" rel="noreferrer">Download here</a>.</li>
            <li className="pl-3">The American Solar Energy Society, and Management Information Services, Inc. 2008. “Defining, Estimating, And Forecasting The Renewable Energy And Energy Efficiency Industries In The U.S And In Colorado”. <a href={CO_Jobs_Final_Report} target="_blank" rel="noreferrer">Download here</a>. <br />Note: Colorado data were not included.<br /></li>
            <li className="pl-3">Pollin, R., Wicks-Lim, J., Garrett-Peltier, H. 2009. “Green Prosperity: How Clean Energy Policies Can Fight Poverty and Raise Living Standards in the United States.” Political Economy Research Institute, Natural Resource Defense Council and Green for All. <a href="https://www.peri.umass.edu/fileadmin/pdf/other_publication_types/green_economics/green_prosperity/Green_Prosperity.pdf" target="_blank" rel="noreferrer">Available here</a>.</li>
            <li className="pl-3">Pollin, Robert, Heidi Garrett-Peltier, James Heintz, and Bracken Hendricks. 2014. “Green Growth: A U.S. Program for Controlling Climate Change and Expanding Job Opportunities.” Political Economy Research Institute and Center for American Progress. <a href="https://www.peri.umass.edu/fileadmin/pdf/Green_Growth_2014/GreenGrowthReport-PERI-Sept2014.pdf" target="_blank" rel="noreferrer">Available here</a>.</li>
            <li className="pl-3">Muro, Mark, et al. 2019. “Advancing Inclusion through Clean Energy Jobs.” Brookings Institute. <a href="https://www.brookings.edu/research/advancing-inclusion-through-clean-energy-jobs/" target="_blank" rel="noreferrer">Available here</a>.</li>
          </ul>
          }
          <p>There is some overlap in authorship for individuals connected to the Political Economy Research Institute (PERI), with three of seven reports including PERI authors. This likely overemphasizes the occupations highlighted in those reports, though there is some variation in the occupations listed across reports that have PERI authors. Additionally, two out of three reports with PERI authors also include authors from other institutions, which in theory means that additional experts contributed to defining which occupations could be green jobs in each study.
          </p>
          <p>The common green jobs in these studies were then combined with a dataset unique to this project, which uses occupation information from the <a href="https://bls.gov" target="_blank" rel="noreferrer">United States Bureau of Labor Statistics</a>.</p>
        </div>

        <h3 className="pt-4 pb-2" id="researchers">About the researchers</h3>
        <div className="row d-flex flex-row">
          <div className="d-flex flex-column col-md-3 align-items-center">
            <img src={KyleGracey} alt="Kyle Gracey, Lead Researcher" width="75%" /> 
            <p>Kyle Gracey, Lead Researcher</p>
          </div>
          <div className="col-md-9">
            <p>Kyle Gracey (<a href="https://twitter.com/gracey_kyle" target="_blank" rel="noreferrer">@gracey_kyle</a>) has been researching the equity of green jobs for more than a decade. His original research has been published in <a href="https://www.jstor.org/stable/10.14321/j.ctt7zt76x" target="_blank" rel="noreferrer">Building a Green Economy: Perspectives from Ecological Economics</a> (Michigan State University Press) among other locations, and presented to the United Nations Secretary-General’s High-level Panel On Global Sustainability, U.S. Green Building Council, BlueGreen Alliance, and more. Contact him at <a href="mailto:kyle@dataforprogress.org">kyle@dataforprogress.org</a>.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="d-flex flex-column col-md-3 align-items-center">
            <img src={BrittanyBennett} alt="Brittany Bennett, Researcher" width="75%" /> 
            <p>Brittany Bennett, Researcher</p>
          </div>
          <div className="col-md-9 h-100">
            <p>Brittany Bennett is an analytics engineer committed to leveraging data to advance climate policy. She is currently the Data Director at the Sunrise Movement and has previously worked at Data for Progress, New Era Colorado, and Engineers for a Sustainable World. You can learn more about Brittany at her website at <a href="http://brittanybennett.com" target="_blank" rel="noreferrer">brittanybennett.com/</a>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
